<template>
    <div class="wrapper-content-page">
        <div class="block-info">
            <div class="block-info__logo">
                <img
                    :src="require('@/assets/images/main/home-page/Logo.png')"
                    data-aos="fade-down"
                    @click="$router.push('/')"
                />
            </div>

            <h3 class="block-info__title" data-aos="fade-right">
                Nine Tales Terms of Service
            </h3>

            <div class="block-info__content">
                <p class="desc-text">Last updated: xx Month xxxx</p>

                <p class="desc-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et
                    faucibus proin nulla malesuada dictum aliquet dis fermentum.
                    Pretium sit tincidunt eu cras dolor vitae. Adipiscing quis
                    velit sed in morbi. Arcu, mi, mi rutrum orci sit nec augue
                    luctus. Nisl amet scelerisque urna sollicitudin quis
                    pharetra. Lectus vitae elementum blandit tempus nisl
                    nascetur nibh a felis. Lobortis ultrices tempus egestas
                    nulla viverra ultrices in pulvinar cursus. Posuere dapibus
                    nisl sit quam.
                </p>

                <p class="desc-text">
                    Nibh ligula elementum tristique mattis. Nullam volutpat
                    interdum nunc, aliquam. Eget congue ultrices augue sociis
                    metus elementum id. Proin dui tincidunt ut cras tincidunt
                    eu. In sed mollis netus libero, fringilla tellus. Nibh amet
                    volutpat pharetra nullam. Odio auctor velit, eleifend netus
                    sit vitae cursus odio non. Malesuada sodales nunc enim arcu
                    convallis. Cras tortor interdum amet vestibulum. Est sed
                    tellus varius sit fringilla varius. Pellentesque pretium,
                    elit hac dui metus. Nisl, aliquet magna neque dolor sit.
                    Amet, adipiscing sit tellus consectetur feugiat nam lacus.
                    Ipsum id adipiscing blandit eu viverra feugiat purus neque.
                    Ipsum dolor euismod non blandit lorem nulla aliquet.
                </p>

                <p class="desc-text">
                    At ut ornare luctus semper viverra placerat nunc, justo. Leo
                    purus aliquet nibh a egestas elementum. Mattis semper
                    interdum proin tempus pretium, elementum lectus elit semper.
                    Faucibus pulvinar viverra pharetra condimentum diam vitae,
                    ullamcorper lacus. Netus mi eu mi sit nec, leo aenean. Sit
                    proin arcu vulputate fusce fermentum nisl, sapien. Nunc,
                    adipiscing in interdum neque neque. Nisl convallis enim mi
                    pellentesque in non vitae. Quis dui scelerisque amet lorem
                    elit scelerisque tempor eu. Ultrices eget felis felis,
                    tempor. Vivamus faucibus sit ridiculus ornare imperdiet in.
                    Tellus mi id nunc sed eget eget id. Amet vivamus ultricies
                    consectetur augue sit massa posuere tristique consequat.
                    Massa sed aliquet sed ac in consectetur duis venenatis.
                    Ultrices massa duis facilisis consequat dui eu a. Enim
                    volutpat, nisl, imperdiet eu nulla et. Nunc arcu id mi
                    habitant gravida neque bibendum. Egestas erat massa donec
                    odio. Cursus augue tellus purus et faucibus ultrices. Risus
                    tortor, non sit nisl, integer adipiscing cras. Nisl fusce
                    gravida lorem amet nisi. Egestas praesent et nam morbi. Quis
                    orci bibendum sit quis mattis. Nisi leo id nisi, lobortis ut
                    quis a.
                </p>

                <p class="desc-text">
                    Sit blandit ipsum erat ac. Ipsum sit sit massa dolor id
                    tellus magna. Metus aliquam venenatis est, auctor vel, eget
                    pellentesque. Vitae, diam faucibus tincidunt interdum varius
                    lorem egestas gravida dignissim. Eu in et ut ut at lorem
                    amet. Sem platea leo id sed odio. Magna mauris pharetra sed
                    quam sem. At in tincidunt purus tortor sed non rhoncus.
                    Aliquam, posuere dignissim tellus maecenas pellentesque nibh
                    aenean auctor nulla. Eu arcu fusce nulla augue volutpat
                    potenti.
                </p>

                <p class="desc-text">
                    Scelerisque orci quam id posuere mattis molestie
                    pellentesque. Vulputate sit non nunc congue elit ipsum, enim
                    a vitae. Ac tristique congue eget metus quis. Congue tempor
                    eu aliquet semper suspendisse tincidunt fermentum rhoncus
                    tempor. Pulvinar feugiat sed tincidunt amet, nunc ipsum
                    potenti pulvinar nibh. Fermentum turpis venenatis nisl duis
                    sed. Tellus ut consectetur blandit sagittis eget. Urna sem
                    tellus nunc hendrerit sit pretium sit tristique sem.
                    Adipiscing pellentesque vitae risus sit vivamus. Ipsum ipsum
                    magna molestie etiam convallis sit rhoncus. Pulvinar amet
                    nulla donec dolor. Nec viverra tellus blandit amet sed
                    semper nibh. Sit sit fringilla tortor lectus tortor aliquet.
                </p>

                <p class="desc-text">
                    Accumsan tempor nibh varius ultrices in felis, mauris. Sem
                    lorem diam metus lorem dui netus mi enim arcu. Orci, sit
                    molestie lectus morbi fames egestas donec ut. Nibh tellus
                    velit, pharetra placerat. Vestibulum, sit tristique
                    consequat, egestas. Enim, dolor pellentesque tellus neque
                    egestas amet arcu. Lacus sed curabitur sed nunc, elementum.
                    Viverra neque non sed libero id eu nisi. Ullamcorper neque
                    lorem nec fringilla ultrices feugiat sem sed habitant.
                    Facilisi duis faucibus urna, lacus ullamcorper mauris, nisi
                    varius vulputate. Scelerisque integer massa feugiat eget ut.
                    Vitae libero, quam tortor tristique enim urna. Elementum
                    scelerisque faucibus amet consequat faucibus lorem et lorem
                    malesuada.
                </p>

                <p class="desc-text">
                    Tincidunt est eleifend sapien lacus. Rhoncus, sagittis non
                    est eget sed orci. Ac neque dictumst lectus elit. Eget
                    condimentum enim id pellentesque nibh vitae nunc aliquet
                    pulvinar. Quis blandit interdum platea nec bibendum et cras.
                    Platea euismod nisl aliquet sed egestas in pharetra mi. Sit
                    volutpat quisque neque, amet.
                </p>

                <p class="desc-text">
                    Duis quisque tempor congue massa. Lectus maecenas sit etiam
                    proin blandit in imperdiet. Sit rutrum praesent fermentum
                    adipiscing nam. Egestas vitae massa massa commodo mattis
                    adipiscing massa velit pretium. Posuere leo, mi suspendisse
                    dictumst varius malesuada. Nunc cursus sed pellentesque erat
                    nibh facilisi tincidunt ultrices. Nisi quisque euismod
                    venenatis, quam dictum amet diam facilisis. Justo sagittis
                    aliquam nulla nisl, cum neque aliquet convallis vivamus.
                    Adipiscing suscipit tincidunt ultrices pulvinar facilisi
                    potenti nibh dignissim fermentum. Sed enim proin nibh
                    faucibus blandit pellentesque. Suspendisse in odio nunc
                    ultrices adipiscing amet. Sagittis, quisque integer tellus
                    enim convallis tristique. Ultricies id ultrices cursus nibh
                    fames sodales maecenas lectus. Consequat placerat faucibus
                    turpis ut consectetur.
                </p>

                <p class="desc-text">
                    Massa eu ullamcorper vitae enim. Ultricies gravida in
                    sollicitudin lectus. Senectus arcu diam scelerisque non
                    dolor venenatis, quam commodo commodo. Ut sed sit bibendum
                    bibendum ut pretium, nec morbi adipiscing. Commodo facilisis
                    mattis nibh curabitur. Faucibus pharetra in lorem nibh. Enim
                    pretium accumsan nibh integer mattis semper nibh. Id
                    condimentum augue scelerisque montes, donec mauris
                    scelerisque metus, tempus. In ac, sit velit nisi, nunc nunc.
                    Faucibus sit dui risus pellentesque hendrerit condimentum
                    sed porttitor.
                </p>

                <p class="desc-text">
                    Vitae vel pretium lorem viverra fermentum, nam sed nulla
                    auctor. Cras aliquet vel enim arcu in donec adipiscing. Sit
                    enim, ac fames senectus hac integer ac. Ante porta feugiat
                    adipiscing turpis nulla. Duis bibendum id ultricies
                    suspendisse posuere elit pretium, tincidunt sagittis.
                </p>
            </div>

            <div class="block-menu">
                <div class="list-menu">
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/privacy">Privacy</router-link>
                        </p>
                    </div>
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/terms">Terms</router-link>
                        </p>
                    </div>
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/contact">Contact</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/page/Term.scss';
</style>
